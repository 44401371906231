/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  CardMedia,
  CardTitle,
  CardBody,
  Typography,
  Link,
  Button,
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { clickEvent, impressionEvent } from '../../utils/publisher';
import { CARD_IMAGE, DEFAULT_APPLYNOW_URL } from '../../utils/constants';
import { getDefaultApplyNowURL, loadEstimatorScript } from '../../utils/payment-estimator-utils';
import TermsAndConditionsDrawer from '../../common/terms-and-conditions-drawer.component';

const ApplyNow = ({ paymentEstimatorVersion, isMobile, id }) => {
  const [applyNowLink, setApplyNowLink] = useState(DEFAULT_APPLYNOW_URL);
  const [showDrawer, setShowDrawer] = useState(false);
  const [paymentEstimatorLoaded, setPaymentEstimatorLoaded] = useState(false);

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const analyticsInteraction = () => {
    clickEvent('plcc-apply now');
  };

  useEffect(() => {
    loadEstimatorScript(
      paymentEstimatorVersion,
      () => setApplyNowLink(getDefaultApplyNowURL(isMobile, 'homepage')), setPaymentEstimatorLoaded(true),
      isMobile
    );
    impressionEvent('plcc-apply now');
  }, []);

  const { ref, clickID } = useImpression({
    data: {
      id,
      name: 'PLCCPod',
      component: 'PLCCPod',
      position: 1,
      type: 'content',
    },
  });

  const applyNowMessage = () => {
    return (
      // eslint-disable-next-line react/no-unknown-property
      <div clickid={clickID} ref={ref}>
        <div className="sui-flex sui-place-content-center sui-bg-subtle sui-px-10 sui-py-6">
          <CardMedia
            className="sui-object-fill"
            src={CARD_IMAGE.PLCN_HOMEDEPOT}
            alt="Credit Card Icon"
            aspect="wide"
          />
        </div>
        <CardBody>
          <div className="sui-flex sui-flex-col sui-gap-4 sui-pt-4 sui-pb-4">
            <Typography variant="h6" weight="bold">
              Get The Card, Get Up to $100 Off*
            </Typography>
            <Typography variant="body-base">
              +6-months everyday financing on purchases over $299** on your
              qualifying purchase when you open a Home Depot Credit Card.
            </Typography>
            <Typography variant="body-xs" color="subtle">
              See{' '}
              <span className="sui-align-text-bottom">
                <Link
                  component="button"
                  onClick={openDrawer}
                  variant="body-xs"
                >
                  terms & conditions
                </Link>
              </span>{' '}
              for offer details.
            </Typography>
          </div>
        </CardBody>
        <div>
          <Button variant="secondary" fullWidth href={applyNowLink} onClick={analyticsInteraction}>Apply Now</Button>
        </div>
      </div>
    );
  };

  return (
    <CardContent
      className="sui-flex sui-flex-col sui-gap-4 sui-h-full sui-w-full"
      clickid={clickID}
      ref={ref}
      data-component="ApplyNow"
    >
      <CardTitle
        disableTypography
        header={(
          <Typography variant="h3">Home Depot Credit Cards</Typography>
        )}
      />
      {showDrawer && (
        <TermsAndConditionsDrawer
          closeDrawer={closeDrawer}
          open={showDrawer}
          applyNowClick={() => clickEvent('plcc-apply now')}
          showFinancing
        />
      )}
      {applyNowMessage()}
    </CardContent>
  );
};

ApplyNow.propTypes = {
  id: PropTypes.string,
  paymentEstimatorVersion: PropTypes.string,
  isMobile: PropTypes.bool,
};
ApplyNow.defaultProps = {
  id: '',
  paymentEstimatorVersion: 'v1.0.29',
  isMobile: false,
};

export default ApplyNow;
