import React from 'react';
import PropTypes from 'prop-types';
import { Button, Overlay, Image } from '@thd-olt-component-react/core-ui';
import { CARD_IMAGE } from '../../utils/constants';
import './apply-now-modal.style.scss';
import { clickEvent } from '../../utils/publisher';

export const ApplyNowModal = (
  {
    closeModal,
    applyNowUrl,
    isMobile,
    price,
    monthlyPayment,
    promotionTerm,
    activeStartDate,
    activeEndDate,
    analyticsFeature,
    promotionDetails,
    interestRate
  }) => {

  const displayIntroOffer = !price || !monthlyPayment || !promotionTerm;

  const redirectToCiti = () => {
    clickEvent(analyticsFeature);
    window.location.href = applyNowUrl;
  };

  const paymentEstimatorOffer = () => {
    return (
      <>
        <h3 className="u__text-align--center u__bold u--paddingTop">
          Suggested Payments of ${monthlyPayment}.00 per month*
        </h3>
        <p className="u__text-align--center u--paddingBottom">
          {(!!activeStartDate && !!activeEndDate)
            && (
              <>
                <br />
                VALID: {activeStartDate} - {activeEndDate}
              </>
            )}
        </p>
        <p className="u--paddingBottom apply-now-line-spacing">
          Making {promotionTerm} suggested payments of ${monthlyPayment}.00 would pay the purchase
          of ${price} by the end of the
          promotion.* <span className="u__bold">Plus, get $25 off</span> purchases of $25-$299⬦
          or <span className="u__bold">$50 off</span> purchases of $300-999⬦
          or <span className="u__bold">$100 off</span> purchases of $1000 or more⬦
          on your next qualifying purchase when you open a new card.
        </p>
      </>
    );
  };

  const introOffer = () => {
    return (
      <>
        <h3 className="u__text-align--center u__bold u--paddingTop u--paddingBottom">
          Get up to $100 OFF⬦
        </h3>
        <p className="u--paddingBottom apply-now-line-spacing">
          on your qualifying purchase, plus receive 6 months everyday financing* on purchases over
          $299 when you open a new card.
        </p>
      </>
    );
  };

  const financingDetails = () => {
    const isAppliancePromo = promotionDetails.includes('Appliance');
    const isTieredPromo = promotionDetails.includes('24 Months');
    const boldText = isTieredPromo ? 'PROMOTIONAL PERIOD: ' : (promotionTerm + ' MONTHS*');
    let promoDetailsText = ' on purchases of $299 or more.';
    if (isAppliancePromo) {
      promoDetailsText = ' on Appliance purchases of $299 or more.';
    } else if (isTieredPromo) {
      promoDetailsText = '12 Months* on Purchases of $299–$998, 18 Months* on Purchases of'
        + ' $999–$1,998 or 24 Months* on Purchases of $1,999 or More.';
    }
    const endText = isTieredPromo ? 'the promotional period' : promotionTerm + ' months';
    const promoDateRange = activeStartDate && activeEndDate ? `VALID: ${activeStartDate} - ${activeEndDate}` : '';
    return (
      <>
        <span className="u__bold">NO INTEREST IF PAID IN FULL WITHIN {boldText}</span>
        {promoDetailsText} Interest will be charged to your account from the purchase date
        if the purchase balance (including premiums for optional credit insurance) is not paid in
        full within {endText}. {promoDateRange}
      </>
    );
  };

  return (
    <Overlay
      modalClass="apply-now-overlay"
      closeButton
      isMobile={isMobile}
      open
      onClose={closeModal}
    >
      <div data-component="ApplyNowModal">
        <div className="apply-now-modal-message">
          <div className="u__text-align--center">
            <Image
              src={CARD_IMAGE.PLCN_HOMEDEPOT}
              className="u--padding apply-now-modal-card-image"
              alt="Credit Card Icon"
              width="105"
              height="73"
            />
          </div>
          <h3 className="u__text-align--center u--paddingTop u--paddingBottom plcc-modal-title">
            THE HOME DEPOT CONSUMER CARD
          </h3>
          <h3 className="u__text-align--center u--paddingBottom">
            Get the support you need to complete your projects.
          </h3>
          <hr />
          {displayIntroOffer && introOffer()}
          {!displayIntroOffer && paymentEstimatorOffer()}
          <div className="u--paddingBottom u--paddingTop apply-now-button-container">
            <Button onClick={redirectToCiti}>
              Apply Now
            </Button>
          </div>
          {!displayIntroOffer && (
            <p className="apply-now-line-spacing apply-now-light-text u--paddingTop u--paddingBottom">
              {financingDetails()}
            </p>
          )}
        </div>
        <div className="u--padding apply-now-terms-and-conditions apply-now-line-spacing">
          <h3 className="u__text-align--center u__bold">Terms & Conditions</h3>
          <div className="u__flex">
            <p className="u--paddingTop u--paddingRight">*</p>
            <p className="u--paddingTop u__bold">
              With credit approval for qualifying purchases made on The Home Depot or EXPO Design
              Center Consumer Credit Card. APR: {interestRate}. Minimum interest charge: $2. See
              card agreement for details including APR applicable to you. Offer valid for consumer
              accounts in good standing; 6 months everyday credit offer is subject to change without
              notice; see store for details. The suggested monthly payment stated is only an estimate
              based on listed purchase amount excluding sales tax, is rounded to the next highest
              dollar amount and does not include additional payments that may be required for an
              existing balance or future purchases. In order to pay your promotional balance by the
              end of the purchase period, you may have to make a payment each month that his higher
              than your required minimum payment.
            </p>
          </div>
          <div className="u__flex">
            <p className="u--paddingTop">⬦</p>
            <p className="u--paddingTop">
              <span className="u__bold">
                Offer is for new accounts and subject to credit approval.{' '}
              </span>
              This offer is redeemable for either a $25 discount on a single-receipt purchase
              of $25 up to $299, a $50 discount on a single-receipt purchase of $300 up to
              $999 or a $100 discount on a single-receipt purchase of $1,000 or more,
              excluding tax and delivery fees, within 30 days of your account open date
              at The Home Depot® retail stores or homedepot.com when made with your The
              Home Depot Consumer Credit Card, The Home Depot Home Improver Card, the
              Pro Xtra Credit Card, or The Home Depot Commercial Account. Coupon is
              on a single transaction and expires upon use. Valid in the U.S., U.S.V.I., Puerto
              Rico and Guam. Offer does not apply to prior purchases, The Home Depot Gift
              Cards or Certificates, third party gift cards,
              installation products or services purchased in home, Tool Rental or to
              Traeger®, Weber®, Bona®, Simplehuman®, Dacor®, Viking®, Fisher & Paykel®,
              Sharp InsightTM, Delonghi®, Buckhaven, Lynx®, AlfrescoTM, OCI, Marvel®,
              Bertazzoni, Aga, Vent-A-Hood®, Samsung Chef Collection, Bosch Benchmark® Series,
              Liebherr, Zephyr, Miele, Signature Kitchen Suite, Jenn-Air®, Thermador®,
              Jeld-Wen® Door Systems, Monogram products and trade styles. Additional
              exclusions may apply which will be reflected at checkout. May not be
              combined with other discounts; ask an Associate for details.
            </p>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

ApplyNowModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  applyNowUrl: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  analyticsFeature: PropTypes.string,
  price: PropTypes.string,
  monthlyPayment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  promotionTerm: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  activeStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  activeEndDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  promotionDetails: PropTypes.string,
  interestRate: PropTypes.string,
};

ApplyNowModal.defaultProps = {
  analyticsFeature: 'plcc-est',
  price: null,
  monthlyPayment: null,
  promotionTerm: null,
  activeStartDate: null,
  activeEndDate: null,
  promotionDetails: '6 MONTHS EVERY DAY FINANCING* ON PURCHASES OF $299 OR MORE',
  interestRate: '29.99%',
};
